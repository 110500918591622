<template>
  <div id="app" class="MatcMainCntr">
    <router-view/>
    <div class="vommondMessage" ref="message">
    </div>
  </div>
</template>

<!-- <style>
 @import 'remixicon/fonts/remixicon.css';
</style> -->
<script>
import css from 'dojo/css'
import win from 'dojo/win'
import Services from 'services/Services'

import { init } from 'commandbar'; 
init('18ed83ea'); 
import Gleap from 'gleap';

// Please make sure to call this method only once!
Gleap.initialize("wFX5e1g66ZZeJeZxxLBYuWvuAUcT9ux4");

export default {
  methods: {
    showSuccess (msg){
		if (this.$refs.message){
				css.add(this.$refs.message, "vommondMessageSuccess");
				css.remove(this.$refs.message, "vommondMessageError vommondMessageHint");
				this.$refs.message.innerHTML = msg;
				setTimeout( () => {
					this.hideMessage()
				},2000);
			}
		},

	showError (msg){
		if (this.message){
			css.add(this.$refs.message, "vommondMessageError");
			css.remove(this.$refs.message, "vommondMessageSuccess vommondMessageHint");
			this.$refs.message.innerHTML = msg;
			setTimeout( () => {
				this.hideMessage()
			},4000);
		}
	},

	showHint (msg){
		if (this.$refs.message){
			css.add(this.$refs.message, "vommondMessageHint");
			css.remove(this.$refs.message, "vommondMessageSuccess vommondMessageSuccess");
			this.$refs.message.innerHTML = msg;
			setTimeout( () => {
				this.hideMessage()
			},4000);
		}
	},

	hideMessage (){
		css.remove(this.$refs.message, "vommondMessageSuccess vommondMessageError vommondMessageHint");
	},

	handler4xx (url, res) {
		if (res.status === 401) {
			alert('Something is wrong. Please login again!')
			Services.getUserService().logout()
			this.$router.push('/')
			this.$root.$emit('MatcLogout', Services.getUserService().GUEST)
		}
		if (res.tokenTimedOut) {
			alert('Your session has expired. Please login again')
			Services.getUserService().logout()
			this.$router.push('/')
			this.$root.$emit('MatcLogout', Services.getUserService().GUEST)
		}
	},
	initNLS () {
		let language = Services.getUserService().getLanguage()
		this.$root.$i18n.locale = language
	},
	async initGleap () {
    	const user = await Services.getUserService().getUser()
		const loggedInUserId = user.id;
		try {
			await Gleap.initialize("wFX5e1g66ZZeJeZxxLBYuWvuAUcT9ux4");
			await Gleap.identify({
				userId: loggedInUserId,
				email: user.email
			});
		} catch (error) {
			console.log("Error" + error)
		}
	}
  },
  watch :{
    '$route' () {
		css.remove(win.body(), 'MatcPublic')
		css.remove(win.body(), 'MatcVisualEditor')
		css.remove(win.body(), 'MatcLight')
    }
  },
  async mounted () {
	Services.setErrorHandler((url, res) => {
		this.handler4xx(url, res)
	})
	this.$root.$on('Success', (msg) => {
		this.showSuccess(msg)
	})
	this.$root.$on('Error', (msg) => {
		this.showError(msg)
	})
	this.$root.$on('Hint', (msg) => {
		this.showHint(msg)
	})
	this.$root.$on('UserLogin', (user) => {
		Services.getUserService().setUser(user)
	})
	css.remove(win.body(), 'MatcPublic')
	this.initNLS();
	this.initGleap();
  }
}
</script>

