import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


let matcRoutes = []

matcRoutes.push({path:'my-account.html', component: () => import(/* webpackChunkName: "about" */ 'views/user/Account.vue')})
matcRoutes.push({path:'reset_password.html', component: () => import(/* webpackChunkName: "about" */ 'views/user/ResetPassword.vue')})
matcRoutes.push({path:'reset_password3.html', component: () => import(/* webpackChunkName: "about" */ 'views/user/ResetPassword.vue')})
matcRoutes.push({path:'404.html', component: () => import(/* webpackChunkName: "about" */ 'views/404.vue')})
matcRoutes.push({path:'logout.html', component: () => import(/* webpackChunkName: "about" */ 'views/LogoutPage.vue')})
matcRoutes.push({path:'help.html', component: () => import(/* webpackChunkName: "about" */ 'views/Help.vue')})
matcRoutes.push({path:'help/:topic.html', component: () => import(/* webpackChunkName: "about" */ 'views/Help.vue')})
matcRoutes.push({path:'help/:topic/:subtopic.html', component: () => import(/* webpackChunkName: "about" */ 'views/Help.vue')})
// Apps
matcRoutes.push({path:'', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Apps.vue'),  meta: {isDarkHeader: true}})

matcRoutes.push({path:'apps/my-apps.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Apps.vue'), meta: {isDarkHeader: true}})
matcRoutes.push({path:'apps/logged_in.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Apps.vue'),  meta: {isDarkHeader: true}})
matcRoutes.push({path:'apps/create-app.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Create.vue')})
matcRoutes.push({path:'apps/:id.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Workspace.vue')})
matcRoutes.push({path:'apps/:id/:tab.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Workspace.vue')})

// MCU
const mcuRoutes = []
mcuRoutes.push({path:'', name: "mcu_home", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashUsers.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'apps.html', name: "mcu_apps", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Apps.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'users.html', name: "mcu_users", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Users.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'notifications.html', name: "mcu_notifications", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Notifications.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'backups.html', name: "mcu_backups", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Backups.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'log.html', name: "mcu_log", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Log.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-users.html', name: "mcu_dash_users", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashUsers.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-performance.html', name: "mcu_dash_performance", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashPerformance.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-db.html', name: "mcu_dash_db", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashDB.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-metrics.html', name: "mcu_dash_metric", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashMetrics.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-analytics.html', name: "mcu_dash_analytic", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashAnalytics.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'errors.html', name: "mcu_dash_error", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Errors.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-open-ai.html', name: "mcu_dash_open_ai", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashOpenAI.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'users/:id.html', name: "mcu_user", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/User.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'restore-backup.html', name: "mcu_backup", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/RestoreBackup.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'organizations.html', name: "mcu_orgs", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Organizations.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'pubs.html', name: "mcu_pubs", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Publications.vue'), meta: {isAdmin:true, isHome:false} })




export default new VueRouter({
  routes: [ 
    {
      path: '/apps/:id/create.html',
      name: 'Editor',
      component: () => import(/* webpackChunkName: "design" */ 'views/apps/Design.vue')
    },
    {
      path: '/apps/:id/design/:sid.html',
      name: 'ScreenEditor',
      component: () => import(/* webpackChunkName: "design" */ 'views/apps/Design.vue')
    },
    {
      path: '/',
      name: '',
      children: matcRoutes,
      component: () => import(/* webpackChunkName: "matc" */ 'views/QUX.vue')
    },
    {
      path: '/mcu/',
      name: 'mcu2',
      children: mcuRoutes,
      component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/MCU.vue')
    },
    {
      path: '/test/DataBindingTree.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/DataBindingTreeTest.vue')
    },
    {
      path: '/test/Tree.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/TreeTest.vue')
    },
    {
      path: '/test/Layer.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/LayerTest.vue')
    },
    {
      path: '/test/Table.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/TableConfTest.vue')
    },
    {
      path: '/test/Color.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/ColorPickerTest.vue')
    },
    {
      path: '/test/Export.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/ExportTest.vue')
    },
    {
      path: '/test/WS.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/WebSocketTest.vue')
    },
    {
      path: '/test/StyledTable.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/StyledTableTest.vue')
    },
    {
      path: '/test/CondStyle.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/ConditionalStyleTest.vue')
    },
    {
      path: '/test/JS.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/JSSandboxTest.vue')
    }, {
      path: '/test/TRest.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/TemplatedRestSettingsTest.vue')
    },
    {
      path: '/test/Theme.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/ThemeDialogTest.vue')
    },   
    {
      path: '/test/DTree.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/DataBindingTreeTest.vue')
    },
    {
      path: '/test/TreeEdit.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/TreeEditorTest.vue')
    }, {
      path: '/test/Auto.html',
      component: () => import(/* webpackChunkName: "unit" */ './unit/AutoCompleteTextareaTest.vue')
      
    }
  ]
})